import * as React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import CallToAction from "../components/CallToAction"
import Heading from "../components/Heading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faNetworkWired,
  faListOl,
} from "@fortawesome/free-solid-svg-icons"
import HomeBanner from "../components/HomeBanner"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import { graphql } from "gatsby"

const NotFoundPage = ({ data }) => {
  const document = data.allPrismicHomePage.edges[0].node.data
  return (
    <Layout>
      <Seo
        title="Web Design Banff, Canmore and the Bow Valley"
        description="We are a digital agency based in the Canadian Rockies. We provide a broad range of services including web design and application development."
      />
      <HomeBanner />
      <Heading>Who We Are</Heading>
      <section className="split halves inset">
        <div>
          <h3 className="text-center">We are Rocky Mountain Web</h3>
          <p>
            We are a digital agency based in the Canadian Rockies. We provide a
            broad range of services including: web design, web application
            development, SEO services, Google Analytics, social media marketing
            and website security.
          </p>
          <p>
            With over 15 years of experience in the industry we work with you to
            ensure that you get the most out of your digital marketing
            initiatives.
          </p>
          <p>
            We don’t believe in “cookie-cutter” digital marketing strategies.
            Instead we get a feel for your business, developing a unique
            strategy that will work well for your organization and more
            importantly, make an intimate connection with your customers.
          </p>
        </div>

        <div>
          <h3 className="text-center">What Makes us Different</h3>
          <p>
            We live, work and play in the Bow Valley. We’d like to think that
            the fresh air and resplendent scenery afford us a unique outlook on
            life. We bring this outlook along with us in everything we do,
            seizing opportunities that some of our city dwelling counterparts
            overlook.
          </p>
          <p>
            Our organization’s core values encompass what we see around us on a
            day to day basis including:
          </p>

          <ul>
            <li>
              <span className="text-blue">Freshness</span> – Strive to be on the
              cutting edge of design and technology.
            </li>
            <li>
              <span className="text-blue">Elevation</span> – Elevate our game
              and better our client’s businesses.
            </li>
            <li>
              <span className="text-blue">Natural Harmony</span> – Never try to
              force it.
            </li>
          </ul>
        </div>
      </section>

      <div className="shaded">
        <Heading>Why Choose Us?</Heading>
        <section className="inset">
          <div>
            <h3 className="text-center">Because We Are Awesome.</h3>
            <p>
              We create experiences that are attractive, simple to use, and
              drive results for your company. We are not your typical web
              development agency. Sure, we’re strong on corporate branding and
              web design, but we’re really focused on making things work for
              your audience … and your business.
            </p>
          </div>
          <div className="hide-mobile flex">
            <div className="card">
              <h3>Individual Approach</h3>
            </div>
            <div className="card">
              <h3>Modern Technologies</h3>
            </div>
            <div className="card">
              <h3>Complex Solutions</h3>
            </div>
          </div>

          <div className="flex">
            <div className="card">
              <div className="info-box">
                <h3 className="hide-desktop">Individual Approach</h3>
                <FontAwesomeIcon
                  icon={faUser}
                  size="2x"
                  color="rgb(36, 110, 185)"
                />
                <div>
                  Your business is no doubt unique. We believe your digital
                  marketing strategy should be equally as unique.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="info-box">
                <h3 className="hide-desktop">Modern Technologies</h3>
                <FontAwesomeIcon
                  icon={faNetworkWired}
                  size="2x"
                  color="rgb(36, 110, 185)"
                />
                <div>
                  We develop in WordPress and Gatsby, talk to us about which
                  technology is the best fit for your business.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="info-box">
                <h3 className="hide-desktop">Complex Solutions</h3>
                <FontAwesomeIcon
                  icon={faListOl}
                  size="2x"
                  color="rgb(36, 110, 185)"
                />
                <div>
                  To be truly effective online you need to have a complex,
                  multifaceted approach to online marketing.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <CallToAction
        heading="Contact Us"
        body="We love talking about the web and how it could help your business grow. So why not get in touch today?"
        buttonText="Contact Us"
        bgColor="rgb(36, 110, 185)"
        link="contact"
      />
    </Layout>
  )
}

export const query = graphql`
  query NotFoundPage {
    allPrismicHomePage {
      edges {
        node {
          data {
            hero_title {
              text
            }
            hero_image {
              thumbnails
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 2000
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            button_text
            button_link {
              url
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
